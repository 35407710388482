import { ReactElement } from 'react';
import PickerOption from './PickerOption';
import { CabModal } from '@CabComponents/CabModal';
import { CabButton } from '@CabComponents/CabButton';
import { Grid } from '@mui/material';


interface IconData {
  id: string | number;
  name: string;
  icon_url: string;
  is_default: boolean;
  showInPicker: boolean;
}

interface Props {
  icons: IconData[];
  onSelect: (icon: IconData) => void;
  onCancel: () => void;
  showModal: boolean;
}


export const IconPicker = ({ icons, showModal, onSelect, onCancel }: Props): ReactElement => {
  const handleSelectIcon = (id: string | number): void => {
    if (id !== -1) {
      const icon = icons.find((i) => i.id === id);
      if (icon) {
        onSelect(icon);
      }
    }
  };

  const handleCancel = (): void => {
    onCancel();
  };

  return (
    <CabModal
      open={showModal}
      onClose={handleCancel}
      closeIcon={true}
      title={'Add Icon'}
      actionButtons={
        <CabButton buttonType='secondary' onClick={handleCancel}>
          Cancel
        </CabButton>
      }
    >
      <Grid container>
        {icons.map(icon => (
          <Grid item xs={3} key={icon.id}>
            <PickerOption src={icon.icon_url} id={icon.id}
              onClick={handleSelectIcon}
              label="" alt={icon.name} isDefault={icon.is_default}/>
          </Grid>
        ))}
      </Grid>
    </CabModal>
  );
};

export default IconPicker;